<template>
<div class="comissiontixian">
  <van-nav-bar
    title="提现"
    left-arrow
    @click-left="onClickLeft"
  />
  <!-- <div class="tixian_main">
    <van-cell class="account_name" :border="false">
      <template #default>
        提现到账户绑定微信号钱包余额
      </template>
    </van-cell>
    <van-form @submit="onSubmit">
      <van-cell :border="false">
        <template #title>真实姓名</template>
      </van-cell>
      <van-field
        class="money_input"
        v-model="realname"
        placeholder="请输入微信实名认证的姓名"
        :rules="[{ required: true, message: '请输入微信实名认证的姓名' }]"
      />
      <van-cell :border="false">
        <template #title>提现金额</template>
      </van-cell>
      <van-field
        v-model="money"
        label="￥"
        type="number"
        readonly
        placeholder="提现金额"
        :rules="[{ required: true, message: '请输入提现金额' }]"
      />
      <van-cell class="tixian_label" :border="false">
        <template #title>可提现金额 &yen;{{money}}</template>
      </van-cell>
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">
          提现
        </van-button>
      </div>
    </van-form>
  </div> -->
  <div class="tixianmingxi">
    <van-cell :border="false" title="提现记录" is-link @click="commissionTixian"></van-cell>
  </div>
  
  <div class="daitixianorder">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="nodata ? '' : '没有更多了'"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="order_list" v-for="(item, index) in list" :key="index">
        <van-cell :border="false" center class="userinfo">
          <template #icon>
            <div>
              <van-image
                width="20px"
                height="20px"
                lazy-load
                round
                fit="cover"
                style="display: block;margin-right: 5px;"
                :src="item.avatar ? item.avatar : 'https://dk.midbest.cn/img/head.ede6594c.png'"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
          </template>
          <template #title>
            <div class="van-ellipsis">{{item.nickname ? item.nickname : '未设置昵称'}}</div>
          </template>
          <template #right-icon>
            <span style="margin-right: 10px;">{{item.ordersn}}</span>
            <van-button size="mini" @click.stop="copay(item.ordersn)">复制</van-button>
          </template>
        </van-cell>
        <van-card
          v-for="(val, key) in item.order_goods"
          :key="key"
          :num="val.total"
          :price="val.realprice"
          :desc="val.optionname"
          :title="val.goodstitle"
          :thumb="val.goodsthumb"
          lazy-load
        >
        </van-card>
        <van-cell center>
          <template #icon>
            <span>{{item.status_desc}}</span>
          </template>
          <template #title>
            <div class="order_total">
              <span>实付：&yen;{{item.money_payed}}</span>
            </div>
          </template>
        </van-cell>
        <van-cell center>
          <template #icon>
            <div class="red">
              {{item.merchfakeapply_desc}}
            </div>
          </template>
          <template #title>
            <div class="order_total">
              <span class="iconme">C：&yen;{{item.money_chajia_real}}</span>
            </div>
          </template>
        </van-cell>
      </div>
    </van-list>
  </div>
</div>
</template>
<script>
export default {
  name: "Tixian",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      money: 0,
      minMoney: 20, // 后台设置的最小提现金额
      shareid: 0,
      merchid: 0,
      userinfo: {},
      wxinfo: {},
      page: 1,
      list: [],
      loading: false,
      nodata: false,
      finished: false,
      realname: '',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 获取可提现金额 和提现金额阈值
      this.getTxcomission();
      this.getinfor();
      this.onLoad();
    },
    getTxcomission() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID") ? localStorage.getItem("SHOP_UID") : "";
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "shopsmobile/order/get_tixian")
        .then(response => {
          console.log(response);
          if (response.data.code == 100000) {
            _that.minMoney = parseFloat(response.data.minMoney);
            _that.money = parseFloat(response.data.data);
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    getinfor() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + 'shopsmobile/user/get_info')
        .then(function(response){
          _that.userinfo = response.data.data;
          console.log(_that.userinfo, "userinfo");
        })
        .catch(function(error){
          console.log(error);
        })
    },
    onSubmit() {
      console.log("ssss");
      // 分情况 1。金额小于50元，
      let _that = null;
      _that = this;
      
      if (parseFloat(this.money) <= parseFloat(this.minMoney)) {
        _that.$toast("提现金额不能少于" + this.minMoney + "元");
        return false;
      }
      
      _that.$dialog.alert({
        showCancelButton: true,
        message: "确定要提现吗？",
        beforeClose:function(action, done) {
          if (action == "confirm") {
            console.log("确认按钮");
            _that.applytixian();
          }
          if (action == "cancel") {
            console.log("确认按钮");
          }
          done();
        }
      });
    },
    // 提现申请提交到后台
    applytixian() {
      let _that = null;
      _that = this;

      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/order/apply_tixian",
          _that.$qs.stringify({
            realname: _that.realname
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            _that.$router.push({
              name: "TixianResult",
              query: {
                t: new Date().getTime()
              }
            });
          }else {
            _that.$toast(response.data.msg ? response.data.msg : "提现失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 查看提现明细
    commissionTixian() {
      // status = 0 // 默认所有
      this.$router.push({
        name: "Withdraw",
        query: {
          t: new Date().getTime()
        }
      });
    }, 
    // 获取订单
    onLoad() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .get(that.$store.state.domain + "shopsmobile/order/get_bill_detail?page_id="+that.page)
        .then(res => {
          that.loading = false;
          that.nodata = false;
          if (res.data.code == 100000) {
            if (that.page == 1) {
              that.list = res.data.data;
            }else{
              res.data.data.forEach(item => {
                that.list.push(item);
              })
            }
            that.page++;
            if (res.data.data.length < 20) {
              that.finished = true;
            }
          }else{
            that.finished = true;
            if (that.page == 1) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.finished = true;
          if (that.page == 1) {
            that.nodata = true;
          }
        })
    },
  }
}
</script>
<style lang="less">
.comissiontixian {
  width: 100%;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  padding-bottom: 60px;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .tixian_main {
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 0 0 10px;
    margin: 10px;
    .account_name {
      background-color: #F9F9F9;
      padding-top: 20px;
      color: #333;
      font-weight: bold;
      font-size: 12px;
      .van-cell__value {
        flex: 1;
        text-align: center;
      }
    }
    .van-form {
      padding-top: 10px;
      overflow: hidden;
      .van-cell {
        font-weight: bold;
      }
      .van-field {
        .van-field__label{
          width: 20px;
          margin-right: 0;
          font-size: 12px;
        }
        .van-field__control{
          font-size: 20px;
        }
      }
      .tixian_label{
        font-size: 12px;
        font-weight: normal;
        color: #999;
      }
    }
    .money_input {
      .van-cell__value {
        flex: 1;
      }
    }
  }
  .tixianmingxi {
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 10px 16px;
    margin: 10px;
    .van-cell {
      padding: 0;
      .van-cell__title {
        flex: 1;
        text-align: left;

      }
    }
  }
  .daitixianorder {
    margin: 10px;
    // 订单列表
    .order_list {
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      margin: 10px 0;
      border-radius: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
          overflow: hidden;
          .order_total {
            width: 100%;
            text-align: right;
            .iconme {
              margin-left: 10px;
              color: #ee0a24;
              border: 1px solid #ee0a24;
              border-radius: 2px;
              padding: 1px 5px;
              font-size: 10px;
            }
          }
        }
      }
      .userinfo .van-cell__title {
        margin-right: 5px;
      }
      .van-card {
        background-color: #fff;
        text-align: left;
        margin-top: 0;
      }
      .red {
        color: #ee0a24;
      }
    }
  }
}
</style>